<template>
  <ModalComponent
    id="update-technician-booking-modal"
    title="Editar booking"
    ok-title="Siguiente"
    size="lg"
    @confirmed="onCreateBooking"
    @show="onShow()"
  >
    <TechnicianBookingForm
      ref="technicianBookingForm"
      :form_prop.sync="form"
    />
  </ModalComponent>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import TechnicianBookingForm from '@/components/Business/Project/TechnicianBooking/Form/TechnicianBookingForm.vue'
import TechnicianBookingFactory from '@/domain/services/TechnicianBookingFactory'

export default {
  name: 'UpdateTechnicianBookingModal',
  components: {
    ModalComponent,
    TechnicianBookingForm
  },
  data () {
    return {
      form: this.form_prop
    }
  },
  computed: {
    ...mapGetters({ technicianBooking: 'technicianBooking/current' })
  },
  methods: {
    onShow () {
      this.form = TechnicianBookingFactory.get(this.technicianBooking)
    },
    async onCreateBooking () {
      const valid = await this.$refs.technicianBookingForm.$refs.technicianBookingForm.validate()
      if (!valid) {
        return
      }
      const bookingToUpdate = this.form
      delete bookingToUpdate.broadcasted
      delete bookingToUpdate.createdAt
      const payload = {
        projectId: this.$route.params.id,
        data: bookingToUpdate
      }
      this.$store.dispatch('technicianBooking/update', payload)
        .then(() => {
          this.$bvModal.hide('update-technician-booking-modal')
          this.$store.dispatch('showConfirmationModal')
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import 'updateTechnicianBookingModal';
</style>
