<template>
  <ModalComponent
    id="position-modal"
    title="Nueva posición"
    ok-title="Añadir"
    deleteText="Añadir y crear otra"
    deleteButton
    size="lg"
    @confirmed="onCreatePosition"
    @deleted="onCreatePositionAndAdd"
    @show="onShow()"
  >
    <HandlerSelector
      v-if="false"
      :position="form"
    />
    <TechnicianPositionNativeForm
      ref="technicianPositionForm"
      :form_prop.sync="form"
      :booking="booking"
    />
    <TechnicianBookingPositionWorkout
      v-if="false"
      ref="technicianPositionForm"
      :form_prop.sync="form"
      :technicianBooking="booking"
    />
  </ModalComponent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import BookingPositionFactory from '@/domain/services/BookingPositionFactory'
import BookingFactory from '@/domain/services/BookingFactory'
import TechnicianPositionNativeForm from '@/components/Business/Project/TechnicianPosition/Form/Native/TechnicianPositionNativeForm.vue'
import HandlerSelector from '@/components/BookingPosition/HandlerSelector'
import TechnicianBookingPositionWorkout
from '@/components/Business/Project/TechnicianPosition/Form/workout/TechnicianBookingPositionWorkout'

export default {
  name: 'CreateTechnicianPositionModal',
  components: {
    TechnicianBookingPositionWorkout,
    HandlerSelector,
    ModalComponent,
    TechnicianPositionNativeForm
  },
  data () {
    return {
      form: this.form_prop,
      booking: null
    }
  },
  computed: {
    ...mapGetters({
      technicianPosition: 'technicianPosition/current',
      technicianBooking: 'technicianBooking/current',
      project: 'project/current'
    })
  },
  methods: {
    ...mapActions('technicianPosition', ['setEmpty']),
    onShow () {
      this.setEmpty()
      this.form = BookingPositionFactory.getBookingPosition(this.technicianPosition)
      this.booking = BookingFactory.getBooking(this.technicianBooking)
    },
    positionCreated (data) {
      this.resetBookingPositionForm()
      this.setBooking()
      this.$bvModal.hide(data.name)
      if (!data.skipSuccessDialog) {
        this.$store.dispatch('showConfirmationModal')
      } else {
        this.$bvModal.show(data.name)
      }
    },
    async onCreatePosition (name, skipSuccessDialog) {
      const valid = await this.$refs.technicianPositionForm.$refs.technicianPositionForm.validate()
      if (!valid) {
        return
      }
      const positionToCreate = this.form
      positionToCreate.hiredByAvify = true
      positionToCreate.pricePerDay = parseInt(this.form.pricePerDay, 10)
      positionToCreate.annotation = this.form.annotation !== '' ? this.form.annotation : null

      const payload = {
        bookingId: this.booking.id,
        data: positionToCreate
      }
      this.$store.dispatch('technicianPosition/create', payload)
        .then(() => {
          this.$bvModal.hide('booking-modal')
          this.$store.dispatch('showConfirmationModal')
          this.$bvModal.hide('position-modal')
          const payloadBooking = {
            projectId: this.$route.params.projectId,
            id: this.booking.id
          }
          this.$store.dispatch('technicianBooking/getOneById', payloadBooking)
        })
    },
    onCreatePositionAndAdd (name) {
      this.onCreatePosition(name, true)
    }
  }
}
</script>

<style lang="less" scoped>
@import "createTechnicianPositionModal";
</style>
