<template>
  <div>
    <b-card
      align="left"
      class="view-booking side-card positions"
      v-if="booking"
      header-tag="header"
      no-body
    >
      <template #header>
        <b-row>
          <b-col cols="9" sm="7" md="6" ><h4>Posiciones del booking</h4></b-col>
          <b-col cols="3" sm="5" md="6" v-if="booking && !booking.getBroadcasted">
            <b-button
              v-b-modal.position-modal
              class="float-right d-inline-block p-0 d-sm-none"
              variant="outline">
              <img :src="require('@/assets/icons/create-icon.svg')" alt="create">
            </b-button>
            <b-button
              v-if="!positions.length > 0 && booking.getOpened"
              v-b-modal.position-modal
              class="float-right d-none d-sm-inline-block"
              variant="primary">
              <img :src="require('@/assets/icons/create-icon.svg')" alt="create">
              Crear posición
            </b-button>
            <b-button
              v-if="positions.length > 0 && booking.getOpened"
              v-b-modal.position-modal
              class="float-right d-none d-sm-inline-block p-0"
              variant="outline">
              <img :src="require('@/assets/icons/create-icon.svg')" alt="create">
            </b-button>
          </b-col>
        </b-row>
        <b-row class="start" v-if="!positions.length > 0">
          <b-col>
            <div><strong>¡Vaya!</strong></div>
            Todavía no hay posiciones añadidas al booking
          </b-col>
        </b-row>
      </template>
      <b-card-body v-if="positions.length > 0" class="position-list">
        <b-container>
          <b-row
            v-for="bookingPosition in positions"
            :key="bookingPosition.getId"
            class="booking-position-item"
          >
            <b-col>
              <component :is="'SummaryTechnicianPosition' + bookingPosition.getNamedHandler"
                :bookingPosition="bookingPosition"
                @onViewDetailPosition="$emit('onViewDetailPosition')"
              />
            </b-col>
          </b-row>
        </b-container>
      </b-card-body>
    </b-card>
    <CreateTechnicianPositionModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CreateTechnicianPositionModal from '@/components/Business/Project/TechnicianPosition/Create/CreateTechnicianPositionModal.vue'
import SummaryTechnicianPositionNative from '@/components/Business/Project/TechnicianPosition/Summary/Native/SummaryTechnicianPositionNative.vue'
import SummaryTechnicianPositionWorkout from '@/components/Business/Project/TechnicianPosition/Summary/Workout/SummaryTechnicianPositionWorkout.vue'

export default {
  name: 'ListTechnicianPosition',
  components: {
    CreateTechnicianPositionModal,
    SummaryTechnicianPositionNative,
    SummaryTechnicianPositionWorkout
  },
  computed: {
    ...mapGetters({ booking: 'technicianBooking/current' }),
    positions () {
      return this.booking.positions
    }
  },
  methods: {
    getViewBookingPositionComponent (handlerName) {
      return 'ViewBookingPosition' + handlerName
    }
  }
}
</script>

<style lang="less" scoped>
@import "listTechnicianPosition";
</style>
