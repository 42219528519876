<template>
  <div>
    <validation-observer ref="technicianPositionForm" v-slot="{ handleSubmit }">
    <b-form v-if="position" @submit.stop.prevent="handleSubmit(checkForm)" autocomplete="off" novalidate>
      <b-row>
      <b-col>
        <h6>Título</h6>
        <validation-provider
          vid="title"
          name="title"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group id="title" label-for="input-title">
            <b-form-input
              id="title"
              type="text"
              name="title"
              :state="getValidationState(validationContext)"
              placeholder="Escribe título de la posición"
              v-model="position.title"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
      <b-row>
        <b-col>
          <h6>Fecha</h6>
          <v-date-picker
            v-model="dateRange"
            color="indigo"
            is-dark
            is-range
            is-expanded
            locale="ES-es"
            :popover="{ placement: 'auto' }"
            @input="dateSelected"
            :available-dates='{ start: technicianBooking.getEventStartAt, end: technicianBooking.getEventEndAt }'
            mode="dateTime"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <b-row>
                <b-col>
                  <validation-provider
                    vid="startDate"
                    name="fecha de inicio"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <b-form-group class="m-0" id="startDate-group" label-for="input-start-date">
                      <b-form-input
                        id="startDate"
                        name="start-date"
                        placeholder="Fecha inicio"
                        :state="getValidationState(validationContext)"
                        readonly
                        :value="inputValue.start"
                        v-on="inputEvents.start"
                      />
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <validation-provider
                    vid="endDate"
                    name="fecha de final"
                    rules="required|todayOn"
                    v-slot="validationContext"
                  >
                    <b-form-group class="m-0" id="endDate-group" label-for="input-end-date">
                      <b-form-input
                        id="endDate"
                        name="end-date"
                        placeholder="Fecha final"
                        readonly
                        :state="getValidationState(validationContext)"
                        :value="inputValue.end"
                        v-on="inputEvents.end"
                      />
                      <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </template>
          </v-date-picker>
          <small class="placeholder font-weight-light d-inline-block mb-3">
            * Solo se pueden seleccionar rango de fechas
          </small>
        </b-col>
      </b-row>
    <b-row>
      <b-col>
        <h6>Anotaciones</h6>
        <validation-provider
          vid="annotation"
          name="annotation"
          :rules="{ required: false }"
          v-slot="validationContext"
        >
          <b-form-group id="annotation" label-for="input-annotation">
            <b-form-textarea
              id="annotation"
              name="annotation"
              v-model="position.annotation"
              :state="getValidationState(validationContext)"
              placeholder="Escribe anotaciones extras (Opcional)"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
      <b-row>
        <b-col>
          <h6>Precio por jornada</h6>
          <validation-provider
            vid="price"
            name="precio"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <b-form-group id="price" label-for="input-price">
              <b-form-input
                id="price"
                type="number"
                name="price"
                :state="getValidationState(validationContext)"
                placeholder="Coste de empresa por jornada"
                v-model="position.pricePerDay"
              ></b-form-input>
              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col>
          <h6>Autónomo</h6>
          <b-form-group id="freelance" label-for="input-freelance">
            <b-form-radio-group
              class="avify-radio filter"
              v-model="position.freelance"
              :options="freelanceOptions"
              name="freelance"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    </validation-observer>
  </div>

</template>

<script>
import { price } from '@/domain/services/JobandTalentPrice'
import { formatPrice } from '@/domain/services/FormatPrice'
export default {
  name: 'TechnicianBookingPositionWorkout',
  props: {
    form_prop: {
      type: Object
    },
    technicianBooking: {
      type: Object,
      require: true
    }
  },
  data () {
    const dateRange = {
      start: null,
      end: null
    }
    return {
      position: this.form_prop,
      price,
      formatPrice,
      freelanceOptions: [
        { value: true, text: 'Sí' },
        { value: false, text: 'No' }
      ],
      popover: false,
      dateRange: dateRange

    }
  },
  created () {
    this.position.startDate = null
    this.position.endDate = null
  },
  watch: {
    form_prop (value) {
      this.position = value
    }
  },
  methods: {
    loadData () {
      this.dateRange = {
        start: this.position.getStartDate ? this.position.getStartDate : null,
        end: this.position.getEndDate ? this.position.getEndDate : null
      }
    },
    dateSelected (date) {
      this.position.startDate = this.formatDate(date.start)
      this.position.endDate = this.formatDate(date.end)
    },
    formatDate (date) {
      return date.getFullYear() + '-' +
        this.lpad(date.getMonth() + 1) + '-' +
        this.lpad(date.getDate()) + ' ' +
        this.lpad(date.getHours()) + ':' +
        this.lpad(date.getMinutes()) + ':' +
        this.lpad(date.getSeconds())
    },
    lpad (value) {
      return String(value).padStart(2, '0')
    }
  }
}
</script>

<style scoped lang="less">
@import "technicianPositionWorkoutForm";
</style>
