<template>
  <ModalComponent
    id="update-position-modal"
    title="Editar posición"
    ok-title="Guardar"
    size="lg"
    @confirmed="onCreatePosition"
    :delete-button="true"
    @deleted="onDeletePosition"
    @show="onShow()"
  >
    <HandlerSelector
      v-if="false"
      :position="form"
      />
    <TechnicianPositionNativeForm
      v-if="!form || form.hiredByAvify"
      ref="technicianPositionForm"
      :form_prop.sync="form"
      :booking="booking"
    />
    <TechnicianBookingPositionWorkout
      v-else-if="form && !form.hiredByAvify"
      ref="technicianPositionForm"
      :form_prop.sync="form"
      :technicianBooking="booking"
    />
    <ModalComponent
      :id="deleteModalId"
      title="¿Cancelar posición?"
      ok-title="Aceptar"
      size="lg"
      @confirmed="onCancelPosition"
      @askConfirmationFunction="onCancelPosition"
      :validation="atLeastOneReasonSelected"
    >
     <BookingCancelPositionModal
       @addOption="addOption"
       @removeOption="removeOption"
       @changeCustomReason="changeCustomReason"
       :showWarning="!atLeastOneReasonSelected"
     />
    </ModalComponent>
  </ModalComponent>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import BookingFactory from '@/domain/services/BookingFactory'
import TechnicianPositionNativeForm from '@/components/Business/Project/TechnicianPosition/Form/Native/TechnicianPositionNativeForm.vue'
import TechnicianBookingPositionFactory from '@/domain/services/TechnicianBookingPositionFactory'
import HandlerSelector from '@/components/BookingPosition/HandlerSelector'
import TechnicianBookingPositionWorkout
from '@/components/Business/Project/TechnicianPosition/Form/workout/TechnicianBookingPositionWorkout'
import BookingCancelPositionModal from '@/components/BookingPosition/BookingCancelPositionModal'

export default {
  name: 'UpdateTechnicianPositionModal',
  components: {
    BookingCancelPositionModal,
    TechnicianBookingPositionWorkout,
    HandlerSelector,
    ModalComponent,
    TechnicianPositionNativeForm
  },
  props: {
    bookingPositionId: {
      type: String
    }
  },
  data () {
    return {
      form: this.form_prop,
      booking: null,
      deleteReasonsSelected: [],
      customReason: ''
    }
  },
  computed: {
    ...mapGetters({
      technicianPosition: 'technicianPosition/current',
      technicianBooking: 'technicianBooking/current',
      project: 'project/current'
    }),
    deleteModalId () {
      return 'cancel-technician-' + this.bookingPositionId
    },
    atLeastOneReasonSelected () {
      return this.deleteReasonsSelected.length !== 0 || this.customReason !== ''
    }
  },
  methods: {
    onShow () {
      this.form = TechnicianBookingPositionFactory.get(this.technicianPosition)
      this.booking = BookingFactory.getBooking(this.technicianBooking)
    },
    onDeletePosition () {
      this.$bvModal.show(this.deleteModalId)
    },
    onCancelRemove () {
      this.$bvModal.hide(this.deleteModalId)
    },
    onConfirmRemove () {
      this.lockScreen()
      const payload = {
        technicianBookingId: this.booking.id,
        technicianBookingPositionId: this.form.id,
        bookingDeletedReasonId: this.deleteReasonsSelected[0]
      }
      this.$store.dispatch('technicianPosition/delete', payload)
        .then(() => {
          this.onCancelRemove()
          this.$bvModal.hide('update-position-modal')
          this.$store.dispatch('showConfirmationModal')
          const payloadBooking = {
            projectId: this.$route.params.projectId,
            id: this.booking.id
          }
          this.$store.dispatch('technicianBooking/getOneById', payloadBooking)
          this.unlockScreen()
        })
    },
    positionCreated (data) {
      this.resetBookingPositionForm()
      this.setBooking()
      this.$bvModal.hide(data.name)
      if (!data.skipSuccessDialog) {
        this.$store.dispatch('showConfirmationModal')
      } else {
        this.$bvModal.show(data.name)
      }
    },
    async onCreatePosition (name, skipSuccessDialog) {
      const valid = await this.$refs.technicianPositionForm.$refs.technicianPositionForm.validate()
      if (!valid) {
        return
      }
      const positionToUpdate = this.form
      delete positionToUpdate.handler
      delete positionToUpdate.jobandtalent
      positionToUpdate.pricePerDay = parseInt(this.form.pricePerDay, 10)
      positionToUpdate.annotation = this.form.annotation !== '' ? this.form.annotation : null
      positionToUpdate.endDate = positionToUpdate.endDate.replace('T', ' ')
      positionToUpdate.startDate = positionToUpdate.startDate.replace('T', ' ')

      const payload = {
        bookingId: this.booking.id,
        data: positionToUpdate
      }
      this.$store.dispatch('technicianPosition/update', payload)
        .then(() => {
          this.$bvModal.hide('booking-modal')
          if (!skipSuccessDialog) {
            this.$store.dispatch('showConfirmationModal')
            this.$bvModal.hide('update-position-modal')
            this.$store.dispatch('technicianBooking/getOneById', { projectId: this.$route.params.projectId, id: this.booking.id })
          }
        })
    },
    async onCancelPosition () {
      if (this.deleteReasonsSelected.length === 0 && this.customReason === '') {
        return false
      }

      const data = {
        technicianBookingId: this.booking.id,
        technicianBookingPositionId: this.technicianPosition.id,
        customDeletedReason: this.customReason,
        deletedReasonsIds: this.deleteReasonsSelected.map(element => {
          return { id: element }
        })
      }

      await this.$store.dispatch('technicianPosition/addDeleteReasons', data)
        .then(() => this.onConfirmRemove())
    },
    addOption (optionId) {
      this.deleteReasonsSelected.push(optionId)
    },
    removeOption (optionId) {
      const index = this.deleteReasonsSelected.indexOf(optionId)
      if (index > -1) {
        this.deleteReasonsSelected.splice(index, 1)
      }
    },
    changeCustomReason (customReason) {
      this.customReason = customReason.target.value
    }
  }
}
</script>

<style lang="less" scoped>
@import "updateTechnicianPositionModal";
</style>
