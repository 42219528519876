<template>
  <b-modal
    id="broadcast-booking"
    modal-class="user-information-modal booking-modals centered"
    centered
    hide-header
    hide-footer
  >
    <b-row>
      <b-col>
        <h2>¡Gestionamos tus contrataciones!</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>
          Si quieres que WORKOUT ETT se encargue de toda la gestión de la contratación de tus
          posiciones para este booking indícanoslo a continuación.
        </p>
       <p>
         Si quieres más información haz <a :href="workoutPDF" target="_blank">click aquí</a>
       </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="position-table">
        <b-row>
          <b-col class="pl-4">
            <h4>Posiciones de tu booking</h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="pr-0 pl-0">
            <b-form @submit.stop.prevent="onBroadcastBooking" autocomplete="off" novalidate v-if="booking">
              <table>
                <tr>
                  <td ></td>
                  <td class="pl-4">Título</td>
                  <td class="d-none d-sm-table-cell">Roles</td>
                  <td class="d-none d-sm-table-cell">Autónomo</td>
                  <td class="d-none d-sm-table-cell">Precio</td>
                  <td class="pr-4">
                    Workout ETT
                    <b-icon-question-circle-fill id="popover-workout"/>
                  </td>
                </tr>
                <tr v-for="position in booking.getPositions" :key="position.getId">
                  <td class="pl-4"><img src="../../../../../../assets/icons/empty_avatar.svg" alt="icon"></td>
                  <td>{{ position.getTitle }}</td>
                  <td class="d-none d-sm-table-cell role">{{ position.getRoles.map(role => role.name).join(", ") }}</td>
                  <td class="d-none d-sm-table-cell">
                          <span v-if="position.getHandler === HANDLER_NATIVE">
                            {{ position.getFreelance ? "Sí" : "No" }}
                          </span>
                    <span v-else>
                            -
                          </span>
                  </td>
                  <td class="d-none d-sm-table-cell">
                    {{ formatPrice(position.getPrice) }}
                    <div class="jobandtalentNotice" v-if="position.getHandler === HANDLER_WORKOUT || bookingPositionWorkout.indexOf(position.getId) >= 0">
                      Coste de empresa {{ formatPrice(workoutPrice(position.getPrice)) }}
                    </div>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="position.handler"
                      :value="HANDLER_WORKOUT"
                      :unchecked-value="HANDLER_NATIVE"
                      :disabled="!position.isHiredByAvify && position.getHandler === HANDLER_WORKOUT"
                    />
                  </td>
                </tr>
              </table>
            </b-form>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="buttons">
      <b-col>
        <b-button class="float-right" variant="secondary" @click="onDiscardBooking">Descartar</b-button>
      </b-col>
      <b-col>
        <b-button class="float-left" variant="primary" @click="$emit('onBroadcastBooking')">Publicar</b-button>
      </b-col>
    </b-row>
    <b-popover target="popover-workout" :show.sync="popover">
      <div class="h4" style="text-align: right">
        <b-icon-x style="color: black" @click="popover = !popover" />
      </div>
      <p>
        Cuando publicas una posición con la opción de contratar con WORKOUT ETT, desde el GRUPO WORKOUT se encargan de toda la gestión y trámites relacionados con la contratación del técnico.
      <h4>VENTAJAS WORKOUT ETT.</h4>

      <ul>
        <li>
          Menor riesgo jurídico, a través del asesoramiento legal y el diseño de la contratación adaptada a las necesidades de la empresa usuaria.
        </li>
        <li>
          Reducción del personal administrativo dedicado a la gestión documental.
        </li>
        <li>
          Formación en Prevención de Riesgos Laborales, gestión de EPI´s y reconocimientos médicos, responsabilidad de la ETT.
        </li>
        <li>
          La Empresa de Trabajo Temporal soporta el abono de nóminas y seguros sociales, así como el control horario.
        </li>
        <li>
          Traslado de costes directos a costes indirectos.
        </li>
        <li>
          Agilidad en los procesos gracias a herramientas de gestión digitales.
        </li>
        <li>
          Interlocutor único dedicado al proyecto que gestiona todas las necesidades de personal.
        </li>
      </ul>
    </b-popover>
  </b-modal>
</template>

<script>

import { mapGetters } from 'vuex'
import { formatPrice } from '@/domain/services/FormatPrice'
import { price as workoutPrice } from '@/domain/services/JobandTalentPrice'
import { HANDLER_NATIVE, HANDLER_WORKOUT } from '@/domain/models/BookingPosition'
import { BIconQuestionCircleFill, BIconX } from 'bootstrap-vue'

export default {
  name: 'BroadcastTechnicianBookingNativeModal',
  components: { BIconQuestionCircleFill, BIconX },
  methods: {
    onDiscardBooking () {
      this.$bvModal.hide('broadcast-booking')
    }
  },
  data () {
    return {
      bookingPositionWorkout: [],
      formatPrice,
      workoutPrice,
      HANDLER_NATIVE,
      HANDLER_WORKOUT,
      popover: false

    }
  },
  computed: {
    ...mapGetters({ booking: 'technicianBooking/current' }),
    canBePublished () {
      return this.booking !== null &&
        this.booking.getOpened &&
        !this.booking.getBroadcasted &&
        this.booking.positions.length > 0
    },
    workoutPDF () {
      return process.env.VUE_APP_IMAGE_API_URL + '/workout/workout_client.pdf'
    }
  }
}
</script>

<style lang="less" scoped>
@import "broadcastTechnicianBookingNativeModal";
</style>
