<template>
  <b-modal
    id="close-booking"
    modal-class="user-information-modal booking-modals centered"
    centered
    size="sm"
    hide-header
    hide-footer
  >
    <b-row>
      <b-col>
        <h4>¿Cerrar el Booking?</h4>
      </b-col>
    </b-row>
    <b-row class="buttons">
      <b-col>
        <b-button class="float-right" variant="secondary" @click="onDiscardClosingBooking">Descartar</b-button>
      </b-col>
      <b-col>
        <b-button class="float-left" variant="primary" @click="$emit('onCloseBooking')">Cerrar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

export default {
  name: 'CloseTechnicianBookingModal',
  methods: {
    onDiscardClosingBooking () {
      this.$bvModal.hide('close-booking')
    }
  }
}
</script>

<style lang="less" scoped>
@import "closeTechnicianBookingModal";
</style>
