<template>
 <b-row>
    <b-col>
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column line-space">
          <h6 class="font-weight-bold d-flex align-items-center">
            <span class="mr-2">{{ bookingPosition.title }}</span>
            <Avify class="ml-2"/>
            <Workout class="ml-2"/>
          </h6>
          <span class="text">
            Fecha:
            <span class="pink font-weight-bold">{{ bookingPosition.getEventDate }}</span>
          </span>
          <span class="text">
            Roles:
            <span class="pink font-weight-bold" v-if="roles">{{ roles }}</span>
            <span class="pink font-weight-bold" v-else>No requerido</span>
          </span>

          <span class="text">
            Habilidades:
            <span class="pink font-weight-bold" v-if="skills">{{ skills }}</span>
            <span class="pink font-weight-bold" v-else>No requeridas</span>
          </span>

          <span class="text">
            Licencias:
            <span class="pink font-weight-bold" v-if="licenses">{{ licenses }}</span>
            <span class="pink font-weight-bold" v-else>No requeridas</span>
          </span>
          <span class="text">
            Precio:
            <span class="pink font-weight-bold">
              {{ formatPrice(bookingPosition.getPricePerDay) }}
            </span>
          </span>
          <span class="text" v-if="bookingPosition.annotation">
            Anotaciones:
            <span class="pink font-weight-bold">{{ bookingPosition.annotation }}</span>
          </span>
        </div>
        <div>
          <b-button
            v-b-modal="bookingPosition.id"
            class="btn float-right btn-outline"
            v-if="editable"
            v-b-modal.update-position-modal
            @click="onEditPosition"
          >
            Editar
          </b-button>
          <UpdateTechnicianPositionModal :bookingPositionId="bookingPosition.id" />
        </div>
      </div>
    </b-col>
    <b-col cols="5" v-if="booking.getBroadcasted">
      <div class="position-side">
        <div v-if="bookingPosition.getOpened" class="opened-position text-nowrap">
          <span class="d-none d-sm-inline-block m-0">posición abierta</span>
          <img
            alt="Posición abierta"
            :src="require('@/assets/icons/booking-opened.svg')"
          >
        </div>
        <div v-if="!bookingPosition.getOpened" class="closed-position text-nowrap">
          <span class="d-none d-sm-inline-block m-0">posición cerrada</span>
          <img
            alt="Posición cerrada"
            :src="require('@/assets/icons/booking-closed.svg')"
          >
        </div>
        <div class="contacted-technicians-technicians" v-on:click="goToPosition">
          <span>{{ totalContactedTechnicians }}</span>
          <img
            :src="require('@/assets/icons/inbox.svg')"
          >
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import UpdateTechnicianPositionModal from '@/components/Business/Project/TechnicianPosition/Update/UpdateTechnicianPositionModal.vue'
import { formatPrice } from '@/domain/services/FormatPrice'
import Workout from '@/components/SmallLogo/Workout.vue'
import Avify from '@/components/SmallLogo/Avify'

export default {
  name: 'SummaryTechnicianPositionWorkout',
  components: {
    Avify,
    Workout,
    UpdateTechnicianPositionModal
  },
  props: {
    bookingPosition: {
      type: Object
    }
  },
  data () {
    return {
      totalContactedTechnicians: 0,
      formatPrice
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    ...mapGetters({ booking: 'technicianBooking/current' }),
    roles () {
      return this.joinArrayNames(this.bookingPosition.getRoles)
    },
    skills () {
      return this.joinArrayNames(this.bookingPosition.getSkills)
    },
    licenses () {
      return this.joinArrayNames(this.bookingPosition.getDrivingLicenses)
    },
    editable () {
      return this.booking !== null && this.booking.getOpened && !this.booking.getBroadcasted
    }
  },
  methods: {
    ...mapMutations('technicianPosition', ['setCurrent']),
    ...mapActions('technicianPosition', ['getTotalContactedTechnicianFromBusiness']),
    async loadData () {
      const payload = {
        bookingId: this.booking.id,
        bookingPositionId: this.bookingPosition.id
      }
      this.totalContactedTechnicians = await this.getTotalContactedTechnicianFromBusiness(payload)
    },
    joinArrayNames (array) {
      return array.map(item => { return item.name }).join(', ')
    },
    onEditPosition () {
      this.setCurrent(this.bookingPosition)
    },
    goToPosition () {
      // this.setCurrent(this.bookingPosition)
      // this.$emit('onViewDetailPosition')
      this.$router.push({
        name: 'detail-technician-position',
        params: {
          projectId: this.$route.params.projectId,
          technicianBookingId: this.$route.params.technicianBookingId,
          technicianPositionId: this.bookingPosition.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "summaryTechnicianPositionWorkout";
</style>
